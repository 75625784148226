import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react"

import { components } from "../components/Submission"
import SEO from "../components/seo"
import Layout from "../components/layout"
const Submission = (props) => {
  const { data } = props
  if (!data) return null
  const pageData = data.prismicSubmission
  const page = pageData.data || {}
  return (
  <Layout theme={props.pageContext.header_theme && props.pageContext.header_theme}>
    <SEO
      context={props.pageContext}
      cpath={props.location.pathname}
    />
    <SliceZone
      slices={page.body}
      components={components}
      context={pageData}
      defaultComponent={() => null}
    />
  </Layout>
  );
};

export default Submission;

export const query = graphql`
  query submissionQuery($uid: String!){
    prismicSubmission(uid: {eq: $uid}) {
      uid
      id
      type
      data {
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...SubmissionHeroSlice
        }
      }
    }
  }
`
