import React from "react"
import { get } from 'lodash';
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroSection = ({ slice }) => {
  const { primary } = slice
  const heading = get(primary, 'heading.text');
  const brief = get(primary, 'brief.text');
  const {
    hero_mobile_image,
    hero_image,
    hero_image_align,
  } = primary
  return (
   <div className="background1 md:pt-40 pt-32 relative flex flex-col-reverse">
     <div className="w-full grid md:grid-cols-2 grid-cols-1 flex justify-between items-center lg:gap-28 gap-10">
       <div>
         <GatsbyImage
           image={getImage(hero_image)} alt={heading}
           className={
             "order-last md:block hidden " +
             (hero_image_align === 'left' ? "md:order-first"  : "")
           }
           width="778"
           height="823"
         />
         <GatsbyImage
           image={getImage(hero_mobile_image)}
           alt={heading}
           className={
             "md:hidden block " +
             (hero_image_align === 'left' ? "md:order-first order-last"  : "")
           }
           width="375"
           height="396"
         />
        </div>
       <div
         className={
           "md:pr-0 pr-0 pl-0 order-first " +
           (hero_image_align === 'left' ? "md:order-last xl:pr-5 lg:pr-10 md:pb-24"  : "lg:pl-12 md:pl-8 lg:pr-10")
         }
       >
       </div>
     </div>
     <div className="w-full mx-auto md:absolute top-0 right-0 h-full">
       <div className="max-w-7xl h-full">
         <div className="w-full grid md:grid-cols-2 grid-cols-1 flex justify-between lg:gap-28 md:gap-10 gap-0 h-full md:pt-32 lg:pt-64 lg:pl-32">
           <div
             className={
               "md:pr-10 pr-4 pl-4 order-first flex flex-col gap-2 " +
               (hero_image_align === 'left' ? "md:order-last xl:pr-5 lg:pr-10 md:pb-24"  : "lg:pl-4 md:pl-4 lg:pr-0 md:pb-24")
             }
           >
             <h2 className="text-blue font-medium 2xl:text-7xl xl:text-6xl lg:text-5xl sm:text-4xl text-3xl tracking-tighter">
               {heading}
             </h2>
             <div></div>
             <h4 className="text-lg uppercase text-blue font-medium pb-3 tracking-wider">
               {brief}
             </h4>
           </div>
         </div>
       </div>
     </div>
     <div
        className="absolute bottom-0 left-0 w-full h-64"
        style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)' }}
     />
   </div>
  );
};

export default HeroSection;

export const query = graphql`
  fragment SubmissionHeroSlice on PrismicSubmissionDataBodyHero {
    id
    slice_type
    primary {
      heading {
        text
        html
      }
      brief {
        text
        html
      }
      hero_image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      hero_image_align
      hero_mobile_image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
